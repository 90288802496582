import { useCallback } from 'react'

import { setAnalyticsEvent } from '../../../analytics/state'
import { useCartContext } from '../../../cart'
import { eScript20230411Version } from '../../../escript/config'
import { useEScriptParamsLoader } from '../../../escript/hooks/useEScriptParamsLoader'
import { invalidCouponCodeError, setEScriptErrors } from '../../../escript/state'
import { EScriptParamsLoader } from '../../../escript/types'
import { setDispensaryCartState } from '../../events/cart'
import { useDispensaryContext } from '../useDispensaryContext'
import { useAddProductsToCart } from './useAddProductsToCart'
import { isIgnorablePromoCodeError, useApplyPromoCodeToCart } from './useApplyPromoCodeToCart'
import { useEmptyCart } from './useEmptyCart'
import { useRemoveDispensaryPromoCodeFromCart } from './useRemoveDispensaryPromoCodeFromCart'
import { useUpdateCartAttributes } from './useUpdateCartAttributes'

export type UseDispensaryUpdateCart = [updateCartDispensary: UpdateCartDispensary]

export type UpdateCartDispensary = (options: {
  forceClearEScriptParams: boolean
  shouldClearDispensaryPromoCode: boolean
}) => Promise<void>

const publishAddEscriptToCartEvent = async (
  eScriptParamsLoader: EScriptParamsLoader,
): Promise<void> => {
  const { loadParams } = eScriptParamsLoader
  const [{ version, cartAttributes, link }] = await Promise.all([loadParams()])

  if (version === 'well_world' && cartAttributes?.type === 'wellWorld' && link !== undefined) {
    setAnalyticsEvent({
      event: 'add_escript_to_cart',
      escript_version: version,
      escript_campaign: 'well_world',
      escript_link: link,
    })
  }

  if (
    version === eScript20230411Version &&
    cartAttributes?.type === 'eScriptCampaign' &&
    link !== undefined
  ) {
    setAnalyticsEvent({
      event: 'add_escript_to_cart',
      escript_version: version,
      escript_campaign: cartAttributes?.attributes.eScriptCampaign,
      escript_link: link,
    })
  }
}

export const useDispensaryUpdateCart = (): UseDispensaryUpdateCart => {
  const dispensaryContext = useDispensaryContext()
  const cartContext = useCartContext()
  const eScriptParamsLoader = useEScriptParamsLoader()

  const [emptyCart] = useEmptyCart({ cartContext, eScriptParamsLoader })
  const [addProductsToCart] = useAddProductsToCart({ eScriptParamsLoader })
  const [removeDispensaryPromoCodeFromCart] = useRemoveDispensaryPromoCodeFromCart({ cartContext })
  const [applyPromoCodeToCart] = useApplyPromoCodeToCart({ eScriptParamsLoader })
  const [updateCartAttributes] = useUpdateCartAttributes({ dispensaryContext, eScriptParamsLoader })

  const { clearParams } = eScriptParamsLoader

  const updateCartDispensary = useCallback<UpdateCartDispensary>(
    async ({ forceClearEScriptParams, shouldClearDispensaryPromoCode }) => {
      await emptyCart()
      await addProductsToCart()
      await removeDispensaryPromoCodeFromCart({ shouldClearDispensaryPromoCode }).catch(
        (err: unknown) => {
          if (isIgnorablePromoCodeError(err)) {
            return
          }
          throw err
        },
      )
      await applyPromoCodeToCart().catch((err: unknown) => {
        if (isIgnorablePromoCodeError(err)) {
          const invalidCouponCodeErrorMessage = invalidCouponCodeError(err)
          if (invalidCouponCodeErrorMessage) {
            setEScriptErrors([
              { _tag: 'InvalidPromoCode', error_message: invalidCouponCodeErrorMessage },
            ])
          }
          console.warn(err)
          return
        }
        throw err
      })
      await updateCartAttributes({
        forceClearEScriptParams,
        shouldClearDispensaryPromoCode,
      })
      await publishAddEscriptToCartEvent(eScriptParamsLoader)
      setDispensaryCartState({ type: 'cart_final' })
      clearParams()
    },
    [
      emptyCart,
      addProductsToCart,
      removeDispensaryPromoCodeFromCart,
      applyPromoCodeToCart,
      updateCartAttributes,
      eScriptParamsLoader,
      clearParams,
    ],
  )

  return [updateCartDispensary]
}
